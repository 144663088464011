/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-width: 100%;
  min-height: 100%;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
